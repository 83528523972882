/**
 * Directでのみ利用する定義はこちらに記載求職者側と同じ項目については共通する
 */

import { TFormOption } from '@/definition/FORM_OPTIONS';

const user_type: Array<TFormOption> = [
  { value: 'direct_manager', children: '利用責任者' },
  { value: 'direct_hr', children: '採用担当者' },
  { value: 'direct_user', children: '一般ユーザー' },
];

const sort = [
  { value: '1', children: '更新日が近い順' },
  { value: '2', children: 'ピックアップ日が近い順' },
  { value: '3', children: 'みんなの意見が多い順' },
  { value: '4', children: 'みんなの意見が少ない順' },
  { value: '5', children: 'じぶんの意見 昇順' },
  { value: '6', children: 'じぶんの意見 降順' },
  { value: '7', children: 'アクション 昇順' },
  { value: '8', children: 'アクション 降順' },
];

/**
 * マッチ度
 */
const offer_mw_status: Array<TFormOption> = [
  { value: '3', children: '高' },
  { value: '2', children: '中' },
  { value: '1', children: '低' },
];

/**
 * ポートフォリオ作品数 公開数の上限は15
 * value が 0 から 15までの値にして返す処理
 */
const portfolio_item_count: Array<TFormOption> = Array.from({ length: 16 }, (_, i) => i + 1).map(
  (i) => ({
    value: (i - 1).toString(),
    children: (i - 1).toString(),
  })
);

/**
 * 企業向けポートフォリオ作品数 登録数は無制限なので99に
 * value が 0 から 99までの値にして返す処理
 */
const direct_portfolio_item_count: Array<TFormOption> = Array.from(
  { length: 100 },
  (_, i) => i + 1
).map((i) => ({
  value: (i - 1).toString(),
  children: (i - 1).toString(),
}));

/**
 * 日付更新系
 * アカウント開設日、最終ログイン日、ポートフォリオ更新日、職務経歴書更新日 など
 */
const last_update: Array<TFormOption> = [
  { value: '1', children: '24時間以内' },
  { value: '2', children: '3日以内' },
  { value: '3', children: '1週間以内' },
  { value: '4', children: '2週間以内' },
  { value: '5', children: '1ヶ月以内' },
  { value: '6', children: '3ヶ月以内' },
  { value: '7', children: '6ヶ月以内' },
];

/**
 * 採用時のポイント
 */
const point: Array<TFormOption> = [
  { value: '1', children: '経歴がマッチしているか' },
  { value: '2', children: 'スキルがマッチしているか' },
  { value: '3', children: '価値観がマッチしているか' },
];

const blank: Array<TFormOption> = [{ value: '1', children: '定義待ち' }];

/**
 * 汎用的な登録状況選択肢
 */
const genericRegistrationStatus: Array<TFormOption> = [
  { value: '1', children: '登録済み' },
  { value: '0', children: '未登録' },
];

/**
 * 管理タグ（旧確認ステータス）
 */
const userAdminTags: Array<TFormOption> = [
  { value: 'ピックアップ', children: 'ピックアップ' },
  { value: '様子見（マイナビ）', children: '様子見（マイナビ）' },
  { value: '架電済み', children: '架電済み' },
  { value: '有効人材（Web）', children: '有効人材（Web）' },
  { value: '有効人材（ゲーム）', children: '有効人材（ゲーム）' },
  { value: '有効人材（スクール）', children: '有効人材（スクール）' },
  { value: '非有効人材（病歴・ブラック）', children: '非有効人材（病歴・ブラック）' },
  { value: '促進予定', children: '促進予定' },
  { value: '更新待ち', children: '更新待ち' },
  { value: 'NG済', children: 'NG済' },
  { value: 'MC登録済み', children: 'MC登録済み' },
  { value: 'MBのみ', children: 'MBのみ' },
  { value: '呼び込み中', children: '呼び込み中' },
  { value: '様子見（山中）', children: '様子見（山中）' },
  { value: 'MC外登録済', children: 'MC外登録済' },
  { value: '成約者（MC）', children: '成約者（MC）' },
  { value: '成約者（MC外）', children: '成約者（MC外）' },
  { value: '更新待ち（山中）', children: '更新待ち（山中）' },
  { value: '更新待ち（MW）', children: '更新待ち（MW）' },
  { value: 'プール（山中）', children: 'プール（山中）' },
  { value: 'プール（MW）', children: 'プール（MW）' },
];

/**
 * オファー 対応状況
 */
const mwStatus: Array<TFormOption> = [
  { value: '1', children: '未完了' },
  { value: '2', children: '保留' },
  { value: '3', children: '完了' },
];

const offer_status = [
  { value: '1', children: '企業がピックアップ' },
  { value: '3', children: '企業が一次面接オファー中' },
  { value: '8', children: '企業がカジュアル面談オファー中' },
  { value: '2', children: '企業がマイナビワークスに相談中' },
  { value: '4', children: '企業が見送り' },
  { value: '6', children: '求職者が一次面接オファーに応諾' },
  { value: '9', children: '求職者がカジュアル面談オファーに応諾' },
  { value: '7', children: '求職者がマイナビワークスに一次面接オファー相談中' },
  { value: '11', children: '求職者がマイナビワークスにカジュアル面談オファー相談中' },
  { value: '5', children: '求職者が一次面接オファー見送り' },
  { value: '10', children: '求職者がカジュアル面談オファー見送り' },
];

const matchingStatus = [
  { value: '3', children: '企業が一次面接オファー中' },
  { value: '8', children: '企業がカジュアル面談オファー中' },
  { value: '2', children: '企業がマイナビワークスに相談中' },
  { value: '4', children: '企業が見送り' },
  { value: '6', children: '求職者が一次面接オファーに応諾' },
  { value: '9', children: '求職者がカジュアル面談オファーに応諾' },
  { value: '7', children: '求職者がマイナビワークスに一次面接オファー相談中' },
  { value: '11', children: '求職者がマイナビワークスにカジュアル面談オファー相談中' },
  { value: '5', children: '求職者が一次面接オファー見送り' },
  { value: '10', children: '求職者がカジュアル面談オファー見送り' },
];

const directSearchStatus = [
  { value: '1', children: 'ピックアップ' },
  { value: '3', children: '一次面接オファー中' },
  { value: '8', children: 'カジュアル面談オファー中' },
  { value: '2', children: 'マイナビワークスに相談中' },
  { value: '4', children: '見送り' },
  { value: '0', children: '回答期限終了' },
];

const directSearchStatusForCompany = [
  ...directSearchStatus,
  { value: '5', children: '見送り' },
  { value: '6', children: 'オファー中' },
  { value: '7', children: 'オファー中' },
];

const matchingSort = [
  { value: '2', children: 'ステータス更新日が近い順' },
  { value: '3', children: 'ステータス更新日が遠い順' },
  { value: '1', children: 'ピックアップ日が近い順' },
];

const actionOptions = [
  { value: '3', children: '一次面接オファーする' },
  { value: '8', children: 'カジュアル面談オファーする' },
  { value: '2', children: 'マイナビワークスに相談する' },
  { value: '4', children: '見送る' },
];

const reactionOptions = [
  { value: '3', children: '一次面接オファーしたい' },
  { value: '8', children: 'カジュアル面談オファーしたい' },
  { value: '2', children: 'マイナビワークスに相談したい' },
  { value: '4', children: '見送りたい' },
];

const search_experienced_year: Array<TFormOption & { select: string[] }> = [
  { value: '1', children: '1年未満', select: ['1'] },
  { value: '2', children: '1年以上', select: ['2', '3', '4', '5', '6'] },
  { value: '3', children: '2年以上', select: ['3', '4', '5', '6'] },
  { value: '4', children: '3年以上', select: ['4', '5', '6'] },
  { value: '5', children: '4年以上', select: ['5', '6'] },
  { value: '6', children: '5年以上', select: ['6'] },
];

export const DIRECT_FORM_OPTIONS = {
  user_type,
  sort,
  offer_status,
  offer_mw_status,
  portfolio_item_count,
  direct_portfolio_item_count,
  blank,
  point,
  last_update,
  genericRegistrationStatus,
  userAdminTags,
  mwStatus,
  matchingStatus,
  matchingSort,
  directSearchStatus,
  directSearchStatusForCompany,
  actionOptions,
  reactionOptions,
  search_experienced_year,
};
